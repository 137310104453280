<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-header">
        <!-- <h6>Credentials</h6> -->
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-6 col-sm-6">
            <form class="search-form" @submit.prevent="doSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search.search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
              </div>
            </form>
          </div>
          <div class="col-sm-2">
            <div class="input-group">
              <b-form-select
                size="sm"
                @change="searchByChanged"
                v-model="search.search_by"
                :options="search_by_list"
                required />
            </div>
          </div>
          <div class="col-4 col-sm-4">
            <button v-if="checkPermission.create" class="btn btn-primary" @click="show.modal_add = true">Create new credential</button>
          </div>
        </div>
      </div>
      <div class="card-body col-md-12" v-loading="loading.list">
        <!-- <p class="text-muted mt-3">Credentials</p> -->
        <!-- <hr/> -->
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Created Date</th>
                <th width="150">Username</th>
                <th>Password</th>
                <th>Callback URL</th>
                <th width="150">Whitelist IP</th>
                <th colspan="4">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in rows" :key="item._id">
                <td>{{ item.created_at_str }}</td>
                <td width="150">{{ item.username }}</td>
                <td>{{ item.password }}</td>
                <td>{{ item.callback_url || '-' }}</td>
                <td width="150">
                  <span v-if="!item.ip_whitelist.length">
                    -
                  </span>
                  <el-tag v-for="(ip, index) in item.ip_whitelist" :key="index" class="mr-2 mb-2">
                    {{ ip }}
                  </el-tag>
                </td>
                <td>
                  <div class="d-flex align-items-end">
                    <button v-if="checkPermission.update" class="btn btn-outline-primary btn-sm" @click="handlerBtnEdit(item)">
                      <i class="ic-edit" />
                    </button> &nbsp;
                    <button v-if="checkPermission.delete" class="btn btn-outline-primary btn-sm" @click="handlerBtnDelete(item._id)">
                      <i class="ic-delete" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <b-pagination v-if="total > search.per_page" v-model="currentPage" :total-rows="total" :per-page="search.per_page"/>
        </div>
      </div>
    </div>
    <integrations-create
      @hide="show.modal_add = false"
      :show="show.modal_add"/>
    <integrations-edit
      @hide="show.modal_edit = false"
      :credential="selected_credential"
      :show="show.modal_edit"
      />
  </div>
</template>
<script>
import { isEqual } from 'lodash';
import moment from 'moment';
import integrations from '../../../api/integrations';
import popupErrorMessages from '../../../library/popup-error-messages';

import IntegrationsCreate from './Create.vue';
import IntegrationsEdit from './Edit.vue';

export default {
  name: 'IntegrationsIndex',
  metaInfo: {
    title: 'Integrations',
  },
  components: {
    IntegrationsCreate,
    IntegrationsEdit,
  },
  data() {
    return {
      loading: {
        list: false,
      },
      show: {
        modal_add: false,
        modal_edit: false,
      },
      search: {
        search: this.$route.query.search || '',
        search_by: this.$route.query.search_by || 'username',
        per_page: this.$route.query.per_page || 10,
        page: this.$route.query.page || 1,
      },
      rows: [],
      total: 0,
      selected_credential: {},
      search_by_list: [
        { text: 'Username', value: 'username' },
        { text: 'Callback URL', value: 'callback_url' },
      ],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading.list = true;
      this.$parent.showLoader();
      const options = {
        page: this.search.page.toString(),
        per_page: this.search.per_page.toString(),
        search: this.search.search,
        search_by: this.search.search_by,
      };
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      const { data: response } = await integrations.getList(this.activeWorkspace._id, options);
      this.$parent.hideLoader();
      this.loading.list = false;
      if (response.rows) {
        this.total = response.total;
        this.rows = response.rows.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          if (!v.ip_whitelist) v.ip_whitelist = [];
          return v;
        });
      }
    },
    searchByChanged() {
      if (this.search.search) {
        this.doSearch();
      }
    },
    doSearch() {
      this.search.page = 1;
      this.getList();
    },
    handlerBtnEdit(item) {
      this.selected_credential = item;
      this.show.modal_edit = true;
    },
    handlerBtnDelete(id) {
      this.$confirm(this.$t('integrations.confirm_delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await integrations.delete(this.activeWorkspace._id, id)
              .catch(() => {});
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);

            this.$message({
              message: this.$t('integrations.success_delete'),
              type: 'success',
            });

            this.search.search = '';
            this.getList();
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
  },
};
</script>
